/* Typography.css */
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");

body {
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: $color_font-default;

  @media (max-width: $bp-tablet-1) {
    // font-size: 1em;
    // line-height: 1.6em;
  }
}

p {
  margin: 0 0 $offset-g2 0;
  color: $color_font-default;
  font-size:1em;
  line-height: 1.4em;

  @media (max-width: $bp-tablet-1) {
    font-size: 0.9em;
  }
}

a {
  color: $color_font-hyperlink;
  &:hover {
    color: $color_font-hyperlink-hover;
  }
  &:focus {
    background: $color_font-hyperlink-hover;
    color:white;
  }
  // &:active {
  //   color: #ff9900;
  // }
}

h1 {
  font-size: 3em;
  line-height: 1.2em;
  font-weight: 300;
  color: $color_font-h1;

  @media (max-width: $bp-tablet-1) {
    font-size: 2em;
  }
}

h2 {
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: $color_font-h2;

  @media (max-width: $bp-tablet-1) {
    font-size: 1.4em;
    // line-height: 40px;
    font-weight: 700;
  }
}

h3 {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 700;
  // letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $color_font-h3;

  @media (max-width: $bp-tablet-1) {
    font-size: 1em;
    line-height: 2em;
  }
}

/* <hr> */
hr {
  margin-top: $offset-g2;
  margin-bottom: $offset-g2;
  border: 0;
  border-top: 1px solid #fff;
}

hr.dotted {
  border-style: dotted;
}

hr.small {
  max-width: 60px;
}
