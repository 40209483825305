@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
/* App.scss */
/* Variables.scss */
/* color */
/* offset: margins & paddings */
/* responsive breakpoints */
/* Colors.css */
.bg-primary {
  background-color: #c0392b; }
  .bg-primary h2,
  .bg-primary h3,
  .bg-primary h4,
  .bg-primary p {
    color: white; }

.bg-secondary {
  background-color: #ffaf00;
  color: black; }

.bg-third {
  background-color: #0091cf; }
  .bg-third p,
  .bg-third ul li {
    color: black; }

.bg-fourth {
  background-color: #01395a; }
  .bg-fourth h2,
  .bg-fourth h3,
  .bg-fourth h4,
  .bg-fourth p,
  .bg-fourth a {
    color: white; }

.bg-white {
  background-color: white; }

.bg-primary,
.bg-secondary a,
.bg-third a {
  color: white; }

.text-primary {
  color: #c0392b; }

/* Base.scss */
/* from index.css */
body {
  margin: 30px 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* (END) from index.css */
.App {
  text-align: center; }
  @media (max-width: 639px) {
    .App {
      text-align: left; } }

.display-none {
  display: none; }

.display-block {
  display: block; }

.display-inline-block {
  display: inline-block; }

/* offsets */
.pad-icon-left {
  margin-left: 10px; }

/* Mixins */
@media (max-width: 479px) {
  .january {
    display: none; } }

.c2a-big {
  display: inline-block;
  border: 3px solid black;
  background: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 50px;
  font-weight: bold; }
  .c2a-big:hover {
    border-color: #ffaf00; }
  @media (max-width: 479px) {
    .c2a-big {
      padding: 10px;
      font-size: 0.9em;
      border-radius: 0px; } }

/* Typography.css */
body {
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333; }

p {
  margin: 0 0 30px 0;
  color: #333;
  font-size: 1em;
  line-height: 1.4em; }
  @media (max-width: 639px) {
    p {
      font-size: 0.9em; } }

a {
  color: black; }
  a:hover {
    color: #ffaf00; }
  a:focus {
    background: #ffaf00;
    color: white; }

h1 {
  font-size: 3em;
  line-height: 1.2em;
  font-weight: 300;
  color: black; }
  @media (max-width: 639px) {
    h1 {
      font-size: 2em; } }

h2 {
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: black; }
  @media (max-width: 639px) {
    h2 {
      font-size: 1.4em;
      font-weight: 700; } }

h3 {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
  color: black; }
  @media (max-width: 639px) {
    h3 {
      font-size: 1em;
      line-height: 2em; } }

/* <hr> */
hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  border-top: 1px solid #fff; }

hr.dotted {
  border-style: dotted; }

hr.small {
  max-width: 60px; }

/* Backdrop.scss */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100; }

/* TitleSection.scss */
section {
  padding: 2em; }
  @media (max-width: 639px) {
    section {
      padding: 1em; } }
  section.img-splash {
    padding-bottom: 0; }
  section > .section-inner {
    display: inline-block;
    max-width: 900px;
    margin: 0 auto;
    width: 100%; }
  section:not(.title):not(.img-splash) {
    padding-top: 0; }
  section#about {
    margin-bottom: 1em; }
  section.title {
    padding-top: 3em;
    /* (END) h2 */
    /* (END) h3 */ }
    section.title#about {
      padding-top: 2em; }
      @media (max-width: 639px) {
        section.title#about {
          padding-top: 1em; } }
    section.title h2 {
      display: inline-block;
      margin: 0 auto;
      position: relative; }
      section.title h2 .line-through {
        background: -webkit-gradient(linear, right bottom, left top, color-stop(47.75%, transparent), color-stop(49.5%, red), color-stop(50.5%, red), color-stop(52.25%, transparent));
        background: linear-gradient(to left top, transparent 47.75%, red 49.5%, red 50.5%, transparent 52.25%); }
    section.title h3 {
      margin: 30px auto 0 auto;
      display: inline-block;
      position: relative; }
    section.title .body {
      max-width: 600px;
      margin: 1em auto; }
    section.title.bg-secondary .body {
      font-weight: bold; }
    section.title.bg-primary h2:before,
    section.title.bg-fourth h2:before {
      border-color: white; }

/* MainImage.css */
/*
  */
.main-img {
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto;
  margin-top: 30px;
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media (max-width: 639px) {
    .main-img {
      margin-top: calc(30px / 2); } }

div.main-img img {
  width: 100%;
  height: auto; }

@media (min-width: 992px) {
  .main-img {
    height: 320px; } }

@media (max-width: 639px) {
  .main-img {
    width: 100%; } }

/* ImgBanner.css */
.callout {
  display: table;
  width: 100%;
  height: 400px;
  color: white;
  background-color: #333;
  /* background: url(../../assets/pininfarina.jpg) no-repeat left top scroll; */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover; */ }

.text-vertical-center {
  width: 90%;
  height: 100%;
  /* min-height: 60vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto; }
  @media (max-width: 639px) {
    .text-vertical-center {
      align-items: flex-start; } }

.text-vertical-center h1 {
  margin: 0;
  padding: 0;
  /* font-size: 4.5em;
      font-weight: 700; */ }

.text-vertical-center h1,
.text-vertical-center h3 {
  color: white;
  text-shadow: 2px 2px 5px black;
  /* font-size: 4.5em;
      font-weight: 700; */ }

@media (max-width: 639px) {
  .text-vertical-center h1 {
    font-size: 1.6em; } }

/* Clients.css */
/* grid */
/*
  https://www.freecodecamp.org/news/how-to-create-an-image-gallery-with-css-grid-e0f0fd666a5c/
  */
.css-grid.services {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(3, 10vw); */
  grid-gap: 15px;
  max-width: 900px;
  margin: 0 auto; }
  .css-grid.services .item {
    width: 100%;
    height: 100%;
    object-fit: cover; }
    .css-grid.services .item .icon-area {
      text-align: left; }
      .css-grid.services .item .icon-area > .fa-stack {
        font-size: 2em; }
    .css-grid.services .item .text-area {
      text-align: left;
      padding: 0 15px; }
      .css-grid.services .item .text-area h3 {
        margin: 15px auto; }
      .css-grid.services .item .text-area p {
        margin-bottom: 0; }

@media (min-width: 640px) and (max-width: 990px) {
  .css-grid.services {
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(2, 10vw); */
    grid-row-gap: 30px; }
  .css-grid.services .item .icon-area > .fa-stack {
    /* background: pink; */
    /* display: inline-block;
    width: 40%; */
    font-size: 3em; } }

@media (max-width: 639px) {
  .css-grid.services {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px; }
  .css-grid.services .item {
    display: flex; }
  .css-grid.services .item .icon-area > .fa-stack {
    /* background: pink; */
    /* display: inline-block;
    width: 40%; */
    font-size: 1.6em; }
  .css-grid.services .item .text-area {
    /* justify-content: center; */
    /* background: orange; */
    flex-grow: 1;
    /* display: inline-block; */
    text-align: left;
    padding: 0 0 0 15px;
    /* width: 60%;
    box-sizing: border-box; */ }
  .css-grid.services .item .text-area h3 {
    margin: 0; }
  .css-grid.services .item .text-area p {
    margin: 0; } }

@media (max-width: 479px) {
  .css-grid.services .item .icon-area > .fa-stack {
    font-size: 1.2em; }
  .css-grid.services .item .text-area {
    flex-grow: 1;
    text-align: left;
    padding: 0 0 0 15px; } }

/* Portfolio.scss */
/*
https://www.freecodecamp.org/news/how-to-create-an-image-gallery-with-css-grid-e0f0fd666a5c/
*/
.css-grid.portfolio {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 10vw);
  grid-gap: 15px;
  margin: 0 auto; }
  @media (min-width: 640px) and (max-width: 767px) {
    .css-grid.portfolio {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 20vw); } }
  @media (max-width: 639px) {
    .css-grid.portfolio {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 60vw); } }
  .css-grid.portfolio .css-grid__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #ddd;
    cursor: pointer; }
    .css-grid.portfolio .css-grid__img > div {
      width: 100%;
      height: 100%; }
  .css-grid.portfolio .css-grid__item {
    position: relative; }
    .css-grid.portfolio .css-grid__item > a {
      display: block;
      width: 100%;
      height: 100%; }
    .css-grid.portfolio .css-grid__item .spec-note {
      width: auto;
      background: black;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      text-align: left;
      font-size: 10px;
      line-height: 14px;
      padding: 3px 5px;
      position: absolute;
      bottom: 5px;
      left: 7px; }

@media (min-width: 768px) {
  .css-grid.portfolio .css-grid__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4; }
  .css-grid.portfolio .css-grid__item--4 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4; }
  .css-grid.portfolio .css-grid__item--5 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5; }
  .css-grid.portfolio .css-grid__item--6 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5; } }

/* Clients.scss */
/* grid */
/*
  https://www.freecodecamp.org/news/how-to-create-an-image-gallery-with-css-grid-e0f0fd666a5c/
  */
.css-grid.clients {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 10vw);
  grid-gap: 15px;
  max-width: 900px;
  margin: 0 auto; }
  @media (min-width: 640px) and (max-width: 1023px) {
    .css-grid.clients {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 20vw); } }
  @media (max-width: 639px) {
    .css-grid.clients {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 30vw); } }
  .css-grid.clients .item__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #ddd; }

/* Contact.css */
footer > .section-inner {
  padding: 0 1em; }

p.email {
  color: white; }

p.copyright {
  padding: 30px 15px;
  padding-top: 0;
  margin: 0 auto; }

.fa-envelope {
  color: white;
  margin-right: 10px; }

.toolbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  height: calc(60px); }

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0; }

.toolbar.no-toolbar {
  width: calc(60px); }

.toolbar__toggle-button {
  width: 100%;
  height: 100%; }

.toolbar__logo {
  margin-left: 15px; }

.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.4rem; }

.spacer {
  flex: 1 1; }

.toolbar__navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; }
  .toolbar__navigation-items ul li {
    padding: 0 0.5rem; }
    .toolbar__navigation-items ul li a {
      color: white;
      text-decoration: none; }
      .toolbar__navigation-items ul li a:hover, .toolbar__navigation-items ul li a:active {
        color: orange; }

.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  max-width: 400px;
  z-index: 101;
  -webkit-transform: translateX(-210px);
          transform: translateX(-210px);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .side-drawer.open {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0; }
    .side-drawer ul li {
      margin: 0;
      padding: 0; }
      .side-drawer ul li a {
        text-decoration: none;
        text-align: left;
        display: block;
        padding: 10px 15px;
        font-weight: bold; }

/* DrawerToggleButton.scss */
.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  box-sizing: border-box; }
  .toggle-button .nav-icon {
    width: 30px; }
    .toggle-button .nav-icon:after, .toggle-button .nav-icon:before,
    .toggle-button .nav-icon div {
      background-color: black;
      border-radius: 3px;
      content: "";
      display: block;
      height: 4px;
      margin: 7px 0;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }

.toolbar:not(.side-drawer-open) {
  background: rgba(255, 255, 255, 0.75);
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; }
  .toolbar:not(.side-drawer-open):hover {
    background: white; }

.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon:before, .toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon:after,
.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon div {
  width: 20px; }

.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon:before {
  -webkit-transform: translateX(6px) translateY(5px) rotate(45deg);
          transform: translateX(6px) translateY(5px) rotate(45deg); }

.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon:after {
  -webkit-transform: translateX(6px) translateY(-5px) rotate(-45deg);
          transform: translateX(6px) translateY(-5px) rotate(-45deg); }

.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon div {
  -webkit-transform: scale(0);
          transform: scale(0); }

.toolbar.side-drawer-open .toggle-button .nav-icon:after, .toolbar.side-drawer-open .toggle-button .nav-icon:before,
.toolbar.side-drawer-open .toggle-button .nav-icon div {
  background-color: black; }

.toolbar.side-drawer-open .toggle-button .nav-icon:before {
  -webkit-transform: translateY(11px) rotate(135deg);
          transform: translateY(11px) rotate(135deg); }

.toolbar.side-drawer-open .toggle-button .nav-icon:after {
  -webkit-transform: translateY(-11px) rotate(-135deg);
          transform: translateY(-11px) rotate(-135deg); }

.toolbar.side-drawer-open .toggle-button .nav-icon div {
  -webkit-transform: scale(0);
          transform: scale(0); }

.toggle-button:focus {
  outline: none; }

