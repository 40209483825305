/* Base.scss */

/* from index.css */
body {
  margin: $offset-g2 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* (END) from index.css */

.App {
  text-align: center;
  @media (max-width: $bp-tablet-1) {
    text-align:left;
  }
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

/* offsets */
.pad-icon-left {
  margin-left: $offset-1;
}

/* Mixins */
@mixin resp-prop-half($prop, $val) {
  #{$prop}: $val;
  @media (max-width: $bp-tablet-1) {
    #{$prop}: calc(#{$val} / 2);
  }
}

.january {
  @media (max-width: $bp-xs-1) {
    display:none;
  }
}

.c2a-big {
  display:inline-block;
  border:3px solid black;
  background:white;
  padding:$offset-g1 $offset-g2;
  text-decoration:none;
  border-radius: 50px;
  font-weight:bold;
  // margin:1em auto;

  &:hover {
    border-color:$color_font-hyperlink-hover;
  }

  @media (max-width: $bp-xs-1) {
    padding:$offset-1;
    font-size:0.9em;
    border-radius: 0px;
  }
}