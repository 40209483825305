/* Portfolio.scss */
/*
https://www.freecodecamp.org/news/how-to-create-an-image-gallery-with-css-grid-e0f0fd666a5c/
*/

.css-grid {
  &.portfolio {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 10vw);
    grid-gap: $offset-g1;
    margin: 0 auto;

    @media (min-width: $bp-tablet) and (max-width: $bp-sm-1) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 20vw);
    }

    @media (max-width: $bp-tablet-1) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 60vw);
    }

    .css-grid__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid $color_border;
      cursor: pointer;

      > div {
        width: 100%;
        height: 100%;
      }
    }

    .css-grid__item {
      position: relative;

      > a {
        display: block;
        width: 100%;
        height: 100%;
      }

      .spec-note {
        width: auto;
        background: black;
        background: rgba(0, 0, 0, 0.7);
        // background: #c0392b;
        color: white;
        text-align: left;
        font-size: 10px;
        line-height: 14px;
        padding: 3px 5px;
        position: absolute;
        bottom: 5px;
        left: 7px;
      }
    }
  }
}

@media (min-width: $bp-sm) {
  .css-grid.portfolio {
    .css-grid__item--1 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 4;
    }

    .css-grid__item--4 {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 4;
    }

    .css-grid__item--5 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    .css-grid__item--6 {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 4;
      grid-row-end: 5;
    }
  }
}
