/* Contact.css */

footer > .section-inner {
  padding:0 1em;
  // background:red;
  h4 {
    
  }
}

p.email {
  color: white;
}

p.copyright {
  padding: $offset-g2 $offset-g1;
  padding-top:0;
  margin: 0 auto;
}

.fa-envelope {
  color: white;
  margin-right: $offset-1;
}
