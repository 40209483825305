/* TitleSection.scss */

section {
  // @include resp-prop-half("padding", $offset-g2);
  padding:2em;
  @media (max-width: $bp-tablet-1) {
    padding:1em;
  }

  &.img-splash {
    padding-bottom: 0;
  }

  > .section-inner {
    display: inline-block;
    max-width: $max-width;
    margin: 0 auto;
    width:100%;
  }

  &:not(.title):not(.img-splash) {
    padding-top: 0;
    // @include resp-prop-half("padding-top", calc(#{$offset-g2} * 2));
    // padding-top:1.5em;
  }

  &#about {
    // @include resp-prop-half("margin-bottom", $offset-g2);
    margin-bottom:1em;
  }

  &.title {
    // border: 1px solid red;
    padding-top:3em;

    &#about {
      padding-top:2em;
      @media (max-width: $bp-tablet-1) {
        padding-top:1em;
      }
    }

    h2 {
      display: inline-block;
      margin: 0 auto;
      // padding-bottom: $offset-g1;
      position: relative;

      .line-through {
        background: linear-gradient(to left top, transparent 47.75%, red 49.5%, red 50.5%, transparent 52.25%);
      }

      // &:before {
      //   content: "";
      //   position: absolute;
      //   width: 50%;
      //   max-width: 100px;
      //   height: 1px;
      //   bottom: 0;
      //   left: 25%;
      //   border-bottom: 1px solid $color_font-default;
      // }
    }
    /* (END) h2 */

    h3 {
      margin: $offset-g2 auto 0 auto;
      display: inline-block;
      position: relative;
    }
    /* (END) h3 */

    .body {
      // text-align:left;
      max-width:600px;
      // margin-left:auto;
      // margin-right:auto;
      margin: 1em auto;
      // margin-top:1em;
      // @include resp-prop-half("margin-top", $offset-g2);
    }

    &.bg-secondary .body {
      font-weight: bold;
    }

    &.bg-primary h2:before,
    &.bg-fourth h2:before {
      border-color: white;
    }
  }
}
