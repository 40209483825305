/* DrawerToggleButton.scss */
// REF: https://w3bits.com/animated-menu-icon-css/

.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: $offset-1 $offset-g1;
  box-sizing: border-box;

  .nav-icon {
    width: $offset-g2;

    &:after,
    &:before,
    div {
      background-color: black;
      border-radius: 3px;
      content: "";
      display: block;
      height: 4px;
      margin: 7px 0;
      transition: all 0.2s ease-in-out;
    }
  }
}

.toolbar:not(.side-drawer-open) {
  // background: rgba(51, 51, 51, 0.75); //333 @ 75%
  background: rgba(255, 255, 255, 0.75); //white @ 75%
  transition: all 0.2s ease-in;

  &:hover {
    background: white;
  }
}

.toolbar:not(.side-drawer-open) {
  .toggle-button:hover {
    .nav-icon {
      &:before,
      &:after,
      div {
        width: $offset-2;
      }

      &:before {
        transform: translateX(6px) translateY(5px) rotate(45deg);
      }

      &:after {
        transform: translateX(6px) translateY(-5px) rotate(-45deg);
      }

      div {
        transform: scale(0);
      }
    }
  }
}

.toolbar.side-drawer-open {
  .toggle-button {
    .nav-icon {
      &:after,
      &:before,
      div {
        background-color: black;
      }

      &:before {
        transform: translateY(11px) rotate(135deg);
      }

      &:after {
        transform: translateY(-11px) rotate(-135deg);
      }

      div {
        transform: scale(0);
      }
    }
  }
}

.toggle-button:focus {
  outline: none;
}
