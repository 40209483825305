/* Clients.scss */
/* grid */
/*
  https://www.freecodecamp.org/news/how-to-create-an-image-gallery-with-css-grid-e0f0fd666a5c/
  */

.css-grid {
  &.clients {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 10vw);
    grid-gap: $offset-g1;
    max-width: $max-width;
    margin: 0 auto;

    @media (min-width: $bp-tablet) and (max-width: 1023px) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 20vw);
    }

    @media (max-width: $bp-tablet-1) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 30vw);
    }

    .item__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid $color_border;
    }
  }
}
