/* Variables.scss */

$max-width: 900px;

/* color */
$color_primary: #c0392b;
$color_secondary: #ffaf00;
$color_three: #0091cf;
$color_four: #01395a;

$color_font-h1: black;
$color_font-h2: black;
$color_font-h3: black;
$color_font-h4: black;
$color_font-default: #333;
$color_font-hyperlink: black;
$color_font-hyperlink-hover: $color_secondary;
$color_font-hyperlink-hover-bg: black;

$color_border: #ddd;

/* offset: margins & paddings */
$offset-1: 10px;
$offset-2: 20px;
$offset-g1: 15px;
$offset-g2: 30px;

/* responsive breakpoints */
$bp-xlg: 1440px;

$bp-lg: 1200px;
$bp-lg-1: 1199px;

$bp-md: 992px;
$bp-md-1: 991px;

$bp-sm: 768px;
$bp-sm-1: 767px;

$bp-xs: 480px;
$bp-xs-1: 479px;
$bp-tablet: 640px;
$bp-tablet-1: 639px;
$bp-xxs: 400px;
$bp-xxs-1: 399px;
