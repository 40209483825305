/* ImgBanner.css */

.callout {
  display: table;
  width: 100%;
  height: 400px;
  color: white;
  background-color: $color_font-default;
  /* background: url(../../assets/pininfarina.jpg) no-repeat left top scroll; */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover; */
}

.text-vertical-center {
  width: 90%;
  height: 100%;
  /* min-height: 60vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto;

  @media (max-width: $bp-tablet-1) {
    align-items:flex-start;
  }
}

.text-vertical-center h1 {
  margin: 0;
  padding: 0;

  /* font-size: 4.5em;
      font-weight: 700; */
}

.text-vertical-center h1,
.text-vertical-center h3 {
  color: white;
  text-shadow: 2px 2px 5px black;
  /* font-size: 4.5em;
      font-weight: 700; */
}

@media (max-width: $bp-tablet-1) {
  .text-vertical-center h1 {
    font-size: 1.6em;
  }
}
