/* MainImage.css */
/*
  */
.main-img {
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto;
  @include resp-prop-half("margin-top", $offset-g2);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

div.main-img img {
  width: 100%;
  height: auto;
}

@media (min-width: $bp-md) {
  .main-img {
    height:320px;
  }
}

@media (max-width: $bp-tablet-1) {
  .main-img {
    width: 100%;
  }
  div.main-img img {
  }
}
